import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError, isAxiosError } from 'axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { FloatingButton } from '../../components/FloatingButton'
import { toast } from '../../components/LsToast'
import { QrCodeScanner } from '../../components/QrCodeScanner'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { OfferDto, TicketDto } from '../../generated/api/access_control'
import { getOffers } from '../../service/offers.service'
import { createTicket, getTicket } from '../../service/tickets.service'

export const TicketsSale: FC = () => {
  const { t } = useTranslation()
  const [ticketId, setTicketId] = useState<string | undefined>(undefined)
  const [ticket, setTicket] = useState<TicketDto | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [offers, setOffers] = useState<OfferDto[]>([])

  const reset = (): void => {
    setTicketId(undefined)
    setError(undefined)
    setTicket(undefined)
  }

  const handleChooseOffer = async (id: string) => {
    try {
      const ticket = (await createTicket(ticketId!, id)).data
      setTicket(ticket)
    } catch (e) {
      toast.error(t('general.notification.unexpectedError'))
    }
  }

  const loadOffers = async () => {
    try {
      const res = await getOffers()
      setOffers(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    }
  }

  useEffect(() => {
    void loadOffers()
  }, [])

  const isScannerVisible = (): boolean => {
    return !ticketId || !!error
  }

  const showScanner = isScannerVisible()

  return (
    <ScreenWrapper title={t('ticketsSale.sale')}>
      <QrCodeScanner
        notification
        visible={showScanner}
        id={ticketId}
        error={error}
        setId={(id) => {
          setTicketId(id)
        }}
        setError={(error) => {
          setError(error)
        }}
        validation={async (id) => {
          try {
            await getTicket(id)
            return { status: 'error', message: t('ticketsSale.error.alreadyExists', { id }) }
          } catch (e: unknown) {
            if (isAxiosError(e)) {
              const error = e as AxiosError
              if (404 === error.response?.status) {
                return { status: 'ok', message: 'Vstupenka úspěšně nahrána na nosič č. KS4S03D' }
              }
              return { status: 'error', message: 'Unknown axios error' }
            }
            return { status: 'error', message: 'Unknown error' }
          }
        }}
      />
      {ticketId && offers && !ticket && (
        <Grid container spacing={3} mb={3}>
          {offers.map((value) => (
            <Grid item key={`${value.id}`} xs={12} sm={6} md={4} lg={2}>
              <Button
                onClick={() => handleChooseOffer(value.id)}
                fullWidth
                sx={{
                  padding: (theme) => theme.spacing(1, 2, 1, 2),
                  backgroundColor: (theme) => theme.palette.common.white,
                  borderRadius: '4px',
                  height: '128px',
                  border: (theme) => `1px solid ${theme.palette.text.disabled}`,
                  display: 'block',
                }}
                variant="outlined"
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {value.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.disabled,
                  }}
                >
                  {value.price},-
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
      {ticket && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              border: (theme) => `5px solid ${theme.palette.secondary.main}`,
              boxSizing: 'border-box',
              maxWidth: { sm: '400px' },
              maxHeight: { sm: '400px' },
              width: { sm: '400px', xs: 'calc(100vw - 20px)' },
              height: { sm: '400px', xs: 'calc(100vw - 20px)' },
            }}
          >
            <Box
              sx={{
                padding: '40px 30px',
                position: 'relative',
                textAlign: 'left',
                width: '100%',
              }}
            >
              <Typography variant="h3" sx={{ color: (theme) => theme.palette.common.black }}>
                {t('scanner.done')}
              </Typography>
              <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                {t('ticketsSale.text', { id: ticket.ticketId })}
              </Typography>
              <Typography variant="h6" sx={{ color: (theme) => theme.palette.common.black, marginTop: '30px' }}>
                {t('ticketsSale.items')}
              </Typography>
              <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary, marginTop: '10px' }}>
                {ticket.offers.map((value) => (
                  <Box>- {value.name}</Box>
                ))}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {(error || ticket) && <FloatingButton onClick={() => reset()} title={t('general.button.scan')} />}
    </ScreenWrapper>
  )
}
