import React, { FC } from 'react'
import Button from '@mui/material/Button'

type Props = {
  title: string
  onClick: () => void
}
export const FloatingButton: FC<Props> = ({ title, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        position: 'fixed',
        bottom: 30,
        left: 10,
        right: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      size="large"
      variant="contained"
    >
      {title}
    </Button>
  )
}
