import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { useTheme } from '@mui/material/styles'

import { Layout } from '../../components/Layout'
import { useJwt } from '../../hooks/useJwt'
import axios from '../../service/axios'
import { roleActions } from '../../store/slices/roleSlice'
import { Unauthorized } from '../../views/Unauthorized'
import { ErrorBoundary } from '../ErrorBoundary'

export const ROLE_ADMIN = 'ACCESS_CONTROL_ADMIN'
export const ROLE_SALE_USER = 'ACCESS_CONTROL_SALE_USER'
export const ROLE_CHECK_USER = 'ACCESS_CONTROL_CHECK_USER'

export const App: React.FC = () => {
  const theme = useTheme()
  const auth = useAuth()
  const dispatch = useDispatch()

  const { getDecodedPayload } = useJwt()

  const [authorized, setAuthorized] = useState<boolean>()

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          void auth.signoutRedirect({ id_token_hint: auth.user?.id_token })
        }
        return Promise.reject(error)
      }
    )

    return () => axios.interceptors.response.eject(responseInterceptor)
  }, [auth.user?.id_token])

  const trySilentSigning = async () => {
    try {
      const user = await auth.signinSilent()

      if (!user) {
        throw new Error('Not logged in')
      }
    } catch {
      void auth.signinRedirect()
    }
  }

  useEffect(() => {
    if (!auth.isAuthenticated) {
      if (!auth.isLoading) {
        void trySilentSigning()
      }
    } else if (!auth.isLoading && auth.isAuthenticated) {
      try {
        const payload = getDecodedPayload(auth.user!.access_token)
        const realmAccess = auth.user ? payload.realm_access : undefined

        const roles: string[] = realmAccess?.roles ?? []
        const isAdmin = roles.includes(ROLE_ADMIN)

        if (!isAdmin && !roles.includes(ROLE_SALE_USER) && !roles.includes(ROLE_CHECK_USER)) {
          throw new Error('missing access control role')
        }

        dispatch(roleActions.setAdmin(isAdmin))
        dispatch(roleActions.setRoles(roles))
        setAuthorized(true)
      } catch (e) {
        setAuthorized(false)
        dispatch(roleActions.setAdmin(false))
      }
    }
  }, [auth.isLoading, auth.isAuthenticated])

  useEffect(() => {
    if (auth.isAuthenticated) {
      const tokenInterceptor = axios.interceptors.request.use((config) => {
        config.headers!.Authorization = `Bearer ${auth.user!.access_token}`

        return config
      })

      return () => axios.interceptors.request.eject(tokenInterceptor)
    }
  }, [auth.user?.access_token, auth.isAuthenticated])

  if (auth.isLoading || authorized === undefined || !auth.user) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.default, height: '100vh', width: '100%' }}
      >
        <CssBaseline />
        <CircularProgress />
      </Grid>
    )
  } else if (!authorized) {
    return <Unauthorized />
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '100%',
        width: '100%',
        color: theme.palette.primary.main,
      }}
    >
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <ErrorBoundary>
            <Layout />
          </ErrorBoundary>
        </BrowserRouter>
      </StyledEngineProvider>
    </Box>
  )
}
