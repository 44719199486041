import React, { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useSound from 'use-sound'
import { Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Scanner } from '@yudiel/react-qr-scanner'

type Props = {
  visible?: boolean
  notification: boolean
  id: string | undefined
  error: string | undefined
  setId: (id: string) => void
  setError: (message: string) => void
  validation?: (id: string) => Promise<{ status: 'ok' | 'error'; message?: string }>
}

export const QrCodeScanner: FC<Props> = ({ notification, validation, visible = true, id, setId, error, setError }) => {
  const { t } = useTranslation()

  const [internalId, setInternalId] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [playSuccess, { stop: stopSuccess }] = useSound('/sounds/success.mp3', { volume: 0.1 })

  const isReadyForScan = !id && !error && !loading && visible
  const processScan = async (id: string) => {
    setLoading(true)
    stopSuccess()
    if (notification) {
      playSuccess()
    }
    if (!!validation) {
      try {
        const result = await validation(id)
        if (result.status === 'ok') {
          setId(id.trim())
        } else {
          setError(result.message ? result.message : 'Unknown error')
        }
      } catch (reason) {
        setError(reason as string)
      } finally {
        setLoading(false)
      }
    } else {
      setId(id.trim())
      setLoading(false)
    }
  }

  useEffect(() => {
    if (internalId && isReadyForScan) {
      void processScan(internalId)
    }
  }, [internalId])

  useEffect(() => {
    setInternalId(undefined)
  }, [id, error])

  return (
    <Box
      sx={{
        display: visible ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          border: (theme) => `5px solid ${theme.palette.secondary.main}`,
          boxSizing: 'border-box',
          display: isReadyForScan ? 'block' : 'none',
          maxWidth: { sm: '400px', xs: '100%' },
          maxHeight: { sm: '400px', xs: '100%' },
          width: { sm: '400px', xs: '100%' },
          height: { sm: '400px', xs: '100%' },
        }}
      >
        <Scanner
          onResult={(text) => {
            if (text) {
              setInternalId(text)
            }
          }}
          onError={(err) => {
            if (err && !error) {
              setError(err.message)
            }
          }}
          components={{ audio: false, tracker: true }}
        />
      </Box>
      {loading && (
        <Box
          sx={{
            position: 'relative',
            border: (theme) => `5px solid ${theme.palette.secondary.main}`,
            boxSizing: 'border-box',
            maxWidth: { sm: '400px' },
            maxHeight: { sm: '400px' },
            width: { sm: '400px', xs: 'calc(100vw - 20px)' },
            height: { sm: '400px', xs: 'calc(100vw - 20px)' },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h1">
              <CircularProgress color="secondary" />
            </Typography>
            <Typography variant="body2" sx={{ margin: (theme) => theme.spacing(1, 1, 1, 1) }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Typography>
          </Box>
        </Box>
      )}
      {!loading && !error && id && (
        <>
          <Box
            sx={{
              position: 'relative',
              border: (theme) => `5px solid ${theme.palette.success.main}`,
              boxSizing: 'border-box',
              maxWidth: { sm: '400px' },
              maxHeight: { sm: '400px' },
              width: { sm: '400px', xs: 'calc(100vw - 20px)' },
              height: { sm: '400px', xs: 'calc(100vw - 20px)' },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <Typography variant="h1" sx={{ color: (theme) => theme.palette.success.main }}>
                {t('scanner.ok')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ margin: (theme) => theme.spacing(1, 1, 1, 1), color: (theme) => theme.palette.text.secondary }}
              >
                {t('scanner.id')}: {id}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      {!loading && error && (
        <Box
          sx={{
            position: 'relative',
            border: (theme) => `5px solid ${theme.palette.error.main}`,
            boxSizing: 'border-box',
            maxWidth: { sm: '400px' },
            maxHeight: { sm: '400px' },
            width: { sm: '400px', xs: 'calc(100vw - 20px)' },
            height: { sm: '400px', xs: 'calc(100vw - 20px)' },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h1" sx={{ color: (theme) => theme.palette.error.main }}>
              {t('scanner.error')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ margin: (theme) => theme.spacing(1, 1, 1, 1), color: (theme) => theme.palette.text.secondary }}
            >
              <Trans i18nKey={error}></Trans>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
