import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError, isAxiosError } from 'axios'

import { FloatingButton } from '../../components/FloatingButton'
import { QrCodeScanner } from '../../components/QrCodeScanner'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { createTicketCheck, getTicket } from '../../service/tickets.service'

export const TicketsMark: FC = () => {
  const { t } = useTranslation()
  const [ticketId, setTicketId] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  const reset = (): void => {
    setTicketId(undefined)
    setError(undefined)
  }

  const showScanner = true

  return (
    <ScreenWrapper title={t('ticketsMark.mark')}>
      <QrCodeScanner
        notification
        visible={showScanner}
        id={ticketId}
        error={error}
        setId={(id) => {
          setTicketId(id)
        }}
        setError={(error) => {
          setError(error)
        }}
        validation={async (id) => {
          try {
            const ticket = await getTicket(id)
            if (new Date(ticket.data.validity) < new Date()) {
              return { status: 'error', message: t('ticketsMark.error.invalid', { id }) }
            }
            await createTicketCheck(ticket.data.id)
            return { status: 'ok' }
          } catch (e: unknown) {
            if (isAxiosError(e)) {
              const error = e as AxiosError
              if (404 === error.response?.status) {
                return { status: 'error', message: t('ticketsCheck.error.notFound', { id }) }
              }
              if (400 === error.response?.status) {
                return { status: 'error', message: t('ticketsCheck.error.done', { id }) }
              }
              return { status: 'error', message: 'Unknown axios error' }
            }
            return { status: 'error', message: 'Unknown error' }
          }
        }}
      />

      {(ticketId || error) && <FloatingButton onClick={() => reset()} title={t('general.button.scan')} />}
    </ScreenWrapper>
  )
}
