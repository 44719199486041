import { TicketsApi } from '../generated/api/access_control'
import axios from './axios'

const ticketsApi = new TicketsApi(undefined, process.env.REACT_APP_BE_URL, axios)

export const getTicket = async (id: string) => {
  return ticketsApi.getTicket(id, 'qrcode')
}

export const createTicket = async (ticketId: string, offerId: string) => {
  return ticketsApi.createTicket({ ticketId, offerId })
}

export const createTicketCheck = async (ticketId: string, productId?: string) => {
  return ticketsApi.createTicketCheck(ticketId, { productId: productId })
}

export const deleteTicketCheck = async (ticketId: string, checkId: string) => {
  return ticketsApi.deleteTicketCheck(ticketId, checkId)
}
