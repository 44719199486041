import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { alpha, Checkbox, FormControlLabel } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { TicketDto } from '../../generated/api/access_control'

type Props = {
  ticket: TicketDto
}
export const Ticket: FC<Props> = ({ ticket }) => {
  const { t } = useTranslation()

  return (
    <Grid container justifyContent="center" sx={{ margin: (theme) => theme.spacing(1, 0, 8, 0) }}>
      {ticket.offers.map((offer) =>
        offer.products.map((value) =>
          [...Array(value.quantity).keys()].map((index) => (
            <Grid
              item
              key={`${offer.id}${index}`}
              xs={12}
              sx={{
                padding: (theme) => theme.spacing(1, 2, 1, 2),
                backgroundColor: (theme) =>
                  index < ticket.checks.filter((v) => v.product.id === value.product.id).length
                    ? alpha(theme.palette.success.main, 0.2)
                    : theme.palette.common.white,
                borderRadius: '4px',
                border: (theme) =>
                  `1px solid ${index < ticket.checks.filter((v) => v.product.id === value.product.id).length ? theme.palette.success.main : theme.palette.text.disabled}`,
                margin: (theme) => theme.spacing(0, 0, 1, 0),
              }}
            >
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                  <Checkbox
                    checked={index < ticket.checks.filter((v) => v.product.id === value.product.id).length}
                    sx={{ marginTop: -1 }}
                  />
                }
                label={
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      {value.product.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    >
                      {index < ticket.checks.filter((v) => v.product.id === value.product.id).length ? (
                        <span>
                          {t('ticketsCheck.checkAt')}{' '}
                          {format(new Date(ticket.checks[index].updatedAt), 'dd.MM.yyyy HH:mm')}
                        </span>
                      ) : (
                        <span>
                          {t('ticketsCheck.validTo')} {format(new Date(ticket.validity), 'dd.MM.yyyy')}
                        </span>
                      )}
                    </Typography>
                  </>
                }
              />
            </Grid>
          ))
        )
      )}
    </Grid>
  )
}
