import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ROLE_ADMIN, ROLE_CHECK_USER, ROLE_SALE_USER } from '../../containers/App'
import { loggedUserRoles } from '../../store/slices/roleSlice'
import { NotFound } from '../../views/NotFound'
import { Settings } from '../../views/Settings'
import { Tickets } from '../../views/Tickets'
import { TicketsCheck } from '../../views/TicketsCheck'
import { TicketsMark } from '../../views/TicketsMark'
import { TicketsSale } from '../../views/TicketsSale'
import { Path } from './enums'

export const BaseRouter: FC = () => {
  const roles = useSelector(loggedUserRoles)
  return (
    <Routes>
      <Route
        path={Path.HOME}
        element={
          <Navigate
            to={
              roles.some((role) => [ROLE_ADMIN, ROLE_SALE_USER].includes(role)) ? Path.TICKETS_SALE : Path.TICKETS_MARK
            }
            replace
          />
        }
      />
      {roles.some((role) => [ROLE_ADMIN, ROLE_SALE_USER].includes(role)) && (
        <Route path={Path.TICKETS_SALE} element={<TicketsSale />} />
      )}
      {roles.some((role) => [ROLE_ADMIN, ROLE_CHECK_USER].includes(role)) && (
        <Route path={Path.TICKETS_MARK} element={<TicketsMark />} />
      )}
      {roles.some((role) => [ROLE_ADMIN, ROLE_SALE_USER, ROLE_CHECK_USER].includes(role)) && (
        <Route path={Path.TICKETS_CHECK} element={<TicketsCheck />} />
      )}
      <Route path={Path.SETTINGS} element={<Settings />} />
      {roles.some((role) => [ROLE_ADMIN].includes(role)) && <Route path={Path.TICKETS} element={<Tickets />} />}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
