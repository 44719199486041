import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { Path } from '../../router/BaseRouter/enums'

export const Header: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Box>
      <Button
        variant="text"
        size="small"
        sx={{
          '&:hover': {
            background: 'none',
          },
        }}
        onClick={() => navigate(Path.HOME)}
      >
        <svg width="129" height="48" viewBox="0 0 129 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.8654 32.847L16.673 20.9643L18.4274 17.1654L21.6977 13.6969L27.2297 28.7262L24.2942 33.4908L13.8654 32.847Z"
            fill="#CBB28A"
          />
          <path
            d="M24.2942 33.4908L28.7326 22.286L30.8303 13.6969L34.3287 20.6338L35.5118 29.0566V32.2096L24.2942 33.4908Z"
            fill="#CBB28A"
          />
          <path d="M97.5243 26.328L103.018 28.4257L99.4642 31.0052L97.5243 26.328Z" fill="#9FC2DE" />
          <path d="M99.9673 25.5222L99.5111 19.3698L103.721 21.2628L102.086 24.516L99.9673 25.5222Z" fill="#9FC2DE" />
          <path d="M104.515 27.6923V23.7207L108.893 24.3517L107.241 27.251L104.515 27.6923Z" fill="#9FC2DE" />
          <path d="M103.429 31.0414L104.043 28.8456H106.704L105.813 31.0414H103.429Z" fill="#9FC2DE" />
          <path d="M105.444 22.4459L106.267 17.7815L109.294 18.3102L108.957 21.6401L105.444 22.4459Z" fill="#9FC2DE" />
          <path d="M108.614 29.9436L110.582 25.4412L112.247 25.8952L114.44 27.0294L108.614 29.9436Z" fill="#9FC2DE" />
          <path d="M110.354 23.3306L112.398 19.0477L114.44 19.7917L114.971 22.1239L110.354 23.3306Z" fill="#9FC2DE" />
          <path
            d="M57.5783 21.7126C58.7998 19.5659 60.889 17.9904 63.2084 17.4681C64.7817 17.1121 66.4125 17.2251 68.0178 17.3445C68.7682 17.3999 69.5314 17.4596 70.2349 17.741C71.774 18.3571 72.776 19.9112 73.5861 21.4248C74.895 23.87 76.0036 26.6072 75.7264 29.4021C74.8929 29.5875 74.0594 29.773 73.2258 29.9585C72.5031 27.4472 71.4372 25.0446 70.0707 22.8531C69.3864 21.7574 68.5785 20.6701 67.4401 20.1414C66.711 19.8025 65.8945 19.7193 65.0972 19.7449C62.6179 19.8259 59.902 21.331 59.4756 23.9105C59.3306 24.7845 59.4649 25.6799 59.4521 26.5667C59.4394 27.4536 59.2368 28.4108 58.593 28.9778C57.0517 30.3337 56.4527 28.4427 56.3738 27.202C56.2523 25.2898 56.6381 23.3669 57.5783 21.7147V21.7126Z"
            fill="#EF85B4"
          />
          <path
            d="M60.3709 25.5968C60.5734 24.857 60.7823 24.0981 61.2215 23.4841C61.5945 22.9618 62.1147 22.5738 62.6562 22.2562C63.3768 21.832 64.1677 21.5101 64.9905 21.4418C67.2737 21.25 69.3416 23.0428 70.4608 25.1533C71.1877 26.5241 71.629 28.0611 71.7441 29.628C71.4052 29.8753 70.9532 29.9371 70.5631 29.7943C69.704 28.5024 68.9984 27.0933 68.4718 25.6159C68.3588 25.3004 68.248 24.97 68.0135 24.7398C67.8067 24.5372 67.5274 24.4392 67.2567 24.3475C65.8411 23.8636 64.1655 23.418 62.9696 24.3496C61.9719 25.1278 61.7161 26.5667 61.5711 27.8693C61.4816 28.6602 61.0552 30.7046 60.0213 29.6813C59.1643 28.835 60.1172 26.5305 60.3709 25.5989V25.5968Z"
            fill="#EF85B4"
          />
          <path
            d="M46.706 23.3775C46.3586 22.286 46.0111 21.1945 45.6636 20.1009C45.4206 19.3377 45.1775 18.5767 44.9345 17.8135C44.7767 17.321 44.3163 16.0952 43.5787 16.8605C43.3697 17.0759 43.4422 17.1483 43.5339 17.4383C43.5637 17.5363 45.4952 23.6994 45.4952 23.6994C45.0624 23.1409 44.7 22.5696 44.3184 21.9897C43.7918 21.1903 42.9178 20.5315 42.2633 19.8344C41.7368 19.2738 41.2081 18.7174 40.6794 18.1588L39.4472 17.9051L39.4408 18.8922L43.5531 24.3134L41.547 24.0384L38.0146 23.4884L36.614 24.0085L37.4348 24.275L43.4849 25.4155C43.4849 25.4155 41.2848 26.5518 41.0375 26.5518C40.7902 26.5518 38.5625 27.9332 38.5625 27.9332L37.8952 29.3274L39.0464 29.3338C39.9311 28.7113 40.7071 28.108 41.6963 27.6774C42.1503 27.4813 42.5511 27.1935 43.0116 27.008C43.3143 26.8865 44.3056 26.7202 44.4911 26.49C44.0519 27.0336 43.6128 27.5772 43.1715 28.1208C42.6385 28.7795 41.8476 29.4468 41.8498 30.3571C41.8498 30.8176 41.8476 31.2759 41.8455 31.7364L43.4508 30.5127L46.2157 26.8311L45.6849 32.5827L46.2584 33.0794L46.6506 32.2587C46.8659 31.4038 47.109 30.5553 47.3413 29.709C47.6206 28.6879 47.6462 26.9249 47.2902 25.9421C47.2902 25.9442 49.8718 32.6637 49.8718 32.6637L50.9398 32.6701C50.9398 32.6701 49.891 30.0288 49.729 29.6323C49.39 28.803 49.0532 27.9737 48.7142 27.1423C48.6375 26.9547 48.4179 26.0402 48.226 25.9464L52.2914 27.9353L53.4383 28.4832L52.9523 27.1999L49.1832 24.9551L54.2889 23.6717C54.4424 23.4116 54.8155 23.0599 54.7686 22.7444C53.7218 22.6186 52.5557 23.0918 51.5282 23.2709C50.667 23.4201 49.8057 23.5715 48.9444 23.7207L54.8986 18.4146C54.6364 18.6491 53.8817 18.4807 53.5641 18.5553C53.0034 18.6896 52.592 19.1992 52.14 19.5317C51.5879 19.9389 51.0358 20.3461 50.4836 20.7532C49.7375 21.3033 48.9935 21.8511 48.2473 22.4011L49.1491 17.8028L49.0809 15.7456C48.3497 16.8264 47.8764 17.8348 47.5396 19.1075C47.3413 19.86 47.1921 20.6253 47.0557 21.3907C46.9704 21.8618 46.5739 22.9618 46.706 23.3775Z"
            fill="#8A348B"
          />
          <path
            d="M89.8774 14.2427L82.9149 17.9179L92.6509 20.8534L95.4841 26.6157L82.1944 34.1389L77.8092 27.1316L91.3548 25.3963L91.5296 24.3709L78.873 22.9874L79.6106 16.5685L89.4852 13.3004L89.8774 14.2427Z"
            fill="#EC6839"
          />
        </svg>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.common.black }}>
          {t('title')}
        </Typography>
      </Button>
    </Box>
  )
}
