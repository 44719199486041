import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ClickAwayListener from '@mui/core/ClickAwayListener'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import SettingsIcon from '@mui/icons-material/Settings'
import TableRowsIcon from '@mui/icons-material/TableRows'
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'

import { ROLE_ADMIN, ROLE_CHECK_USER, ROLE_SALE_USER } from '../../containers/App'
import { ErrorBoundary } from '../../containers/ErrorBoundary'
import { BaseRouter } from '../../router/BaseRouter'
import { Path } from '../../router/BaseRouter/enums'
import { loggedUserRoles } from '../../store/slices/roleSlice'
import { Header } from '../Header'
import { LSToastContainer } from '../LsToast'

import 'react-toastify/dist/ReactToastify.css'

const drawerWidth = 340

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  boxShadow: 'none',
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export const Layout: FC = () => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const goTo = (path: string) => {
    setOpen(false)
    navigate(path)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const auth = useAuth()
  const roles = useSelector(loggedUserRoles)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const logout = () => {
    void auth.signoutRedirect({ id_token_hint: auth.user?.id_token })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Header />
        </Toolbar>
      </AppBar>
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => handleDrawerClose()}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {roles.some((role) => [ROLE_ADMIN, ROLE_SALE_USER].includes(role)) && (
              <ListItem disablePadding>
                <ListItemButton onClick={() => goTo(Path.TICKETS_SALE)}>
                  <ListItemIcon>
                    <PointOfSaleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('ticketsSale.title')} />
                </ListItemButton>
              </ListItem>
            )}
            {roles.some((role) => [ROLE_ADMIN, ROLE_CHECK_USER].includes(role)) && (
              <ListItem disablePadding>
                <ListItemButton onClick={() => goTo(Path.TICKETS_MARK)}>
                  <ListItemIcon>
                    <ConfirmationNumberIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('ticketsMark.title')} />
                </ListItemButton>
              </ListItem>
            )}
            {roles.some((role) => [ROLE_ADMIN, ROLE_SALE_USER, ROLE_CHECK_USER].includes(role)) && (
              <ListItem disablePadding>
                <ListItemButton onClick={() => goTo(Path.TICKETS_CHECK)}>
                  <ListItemIcon>
                    <PointOfSaleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('ticketsCheck.title')} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Divider />
          {roles.some((role) => [ROLE_ADMIN].includes(role)) && (
            <>
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => goTo(Path.TICKETS)}>
                    <ListItemIcon>
                      <TableRowsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('tickets.title')} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => goTo(Path.SETTINGS)}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('settings.title')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t('logout')} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </ClickAwayListener>
      <Main open={open}>
        <DrawerHeader />
        <LSToastContainer />
        <Box>
          <ErrorBoundary>
            <BaseRouter />
          </ErrorBoundary>
        </Box>
      </Main>
    </Box>
  )
}
