/* tslint:disable */
/* eslint-disable */
/**
 * Access control API
 * API for Access control tool
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'price': number;
    /**
     * 
     * @type {Array<ProductQuantityDto>}
     * @memberof OfferDto
     */
    'products': Array<ProductQuantityDto>;
}
/**
 * 
 * @export
 * @interface PoolDto
 */
export interface PoolDto {
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductCheckDto
 */
export interface ProductCheckDto {
    /**
     * 
     * @type {string}
     * @memberof ProductCheckDto
     */
    'id': string;
    /**
     * 
     * @type {ProductDto}
     * @memberof ProductCheckDto
     */
    'product': ProductDto;
    /**
     * 
     * @type {string}
     * @memberof ProductCheckDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCheckDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCheckDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCheckDto
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCheckDto
     */
    'updatedBy': string;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'name': string;
    /**
     * 
     * @type {PoolDto}
     * @memberof ProductDto
     */
    'pool': PoolDto;
}
/**
 * 
 * @export
 * @interface ProductQuantityDto
 */
export interface ProductQuantityDto {
    /**
     * 
     * @type {string}
     * @memberof ProductQuantityDto
     */
    'id': string;
    /**
     * 
     * @type {ProductDto}
     * @memberof ProductQuantityDto
     */
    'product': ProductDto;
    /**
     * 
     * @type {number}
     * @memberof ProductQuantityDto
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface TicketCheckReqDto
 */
export interface TicketCheckReqDto {
    /**
     * 
     * @type {string}
     * @memberof TicketCheckReqDto
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketCheckReqDto
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface TicketDto
 */
export interface TicketDto {
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'ticketId': string;
    /**
     * 
     * @type {Array<OfferDto>}
     * @memberof TicketDto
     */
    'offers': Array<OfferDto>;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'validity': string;
    /**
     * 
     * @type {Array<ProductCheckDto>}
     * @memberof TicketDto
     */
    'checks': Array<ProductCheckDto>;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'updatedBy': string;
}
/**
 * 
 * @export
 * @interface TicketReqDto
 */
export interface TicketReqDto {
    /**
     * 
     * @type {string}
     * @memberof TicketReqDto
     */
    'ticketId': string;
    /**
     * 
     * @type {string}
     * @memberof TicketReqDto
     */
    'offerId': string;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.health']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<string> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OffersApi - axios parameter creator
 * @export
 */
export const OffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOffers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OffersApi - functional programming interface
 * @export
 */
export const OffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOffers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOffers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.getAllOffers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OffersApi - factory interface
 * @export
 */
export const OffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OffersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOffers(options?: any): AxiosPromise<Array<OfferDto>> {
            return localVarFp.getAllOffers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OffersApi - object-oriented interface
 * @export
 * @class OffersApi
 * @extends {BaseAPI}
 */
export class OffersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getAllOffers(options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).getAllOffers(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TicketsApi - axios parameter creator
 * @export
 */
export const TicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TicketReqDto} ticketReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: async (ticketReqDto: TicketReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketReqDto' is not null or undefined
            assertParamExists('createTicket', 'ticketReqDto', ticketReqDto)
            const localVarPath = `/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TicketCheckReqDto} ticketCheckReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketCheck: async (id: string, ticketCheckReqDto: TicketCheckReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createTicketCheck', 'id', id)
            // verify required parameter 'ticketCheckReqDto' is not null or undefined
            assertParamExists('createTicketCheck', 'ticketCheckReqDto', ticketCheckReqDto)
            const localVarPath = `/tickets/{id}/checks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketCheckReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} checkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketCheck: async (id: string, checkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTicketCheck', 'id', id)
            // verify required parameter 'checkId' is not null or undefined
            assertParamExists('deleteTicketCheck', 'checkId', checkId)
            const localVarPath = `/tickets/{id}/checks/{checkId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"checkId"}}`, encodeURIComponent(String(checkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTickets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GetTicketIdTypeEnum} [idType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket: async (id: string, idType?: GetTicketIdTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicket', 'id', id)
            const localVarPath = `/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idType !== undefined) {
                localVarQueryParameter['idType'] = idType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsApi - functional programming interface
 * @export
 */
export const TicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TicketReqDto} ticketReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicket(ticketReqDto: TicketReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicket(ticketReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TicketsApi.createTicket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {TicketCheckReqDto} ticketCheckReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicketCheck(id: string, ticketCheckReqDto: TicketCheckReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicketCheck(id, ticketCheckReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TicketsApi.createTicketCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} checkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketCheck(id: string, checkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketCheck(id, checkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TicketsApi.deleteTicketCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTickets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTickets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TicketsApi.getAllTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {GetTicketIdTypeEnum} [idType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicket(id: string, idType?: GetTicketIdTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicket(id, idType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TicketsApi.getTicket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TicketsApi - factory interface
 * @export
 */
export const TicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {TicketReqDto} ticketReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket(ticketReqDto: TicketReqDto, options?: any): AxiosPromise<TicketDto> {
            return localVarFp.createTicket(ticketReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TicketCheckReqDto} ticketCheckReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketCheck(id: string, ticketCheckReqDto: TicketCheckReqDto, options?: any): AxiosPromise<ProductCheckDto> {
            return localVarFp.createTicketCheck(id, ticketCheckReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} checkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketCheck(id: string, checkId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicketCheck(id, checkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTickets(options?: any): AxiosPromise<Array<TicketDto>> {
            return localVarFp.getAllTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {GetTicketIdTypeEnum} [idType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket(id: string, idType?: GetTicketIdTypeEnum, options?: any): AxiosPromise<TicketDto> {
            return localVarFp.getTicket(id, idType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketsApi - object-oriented interface
 * @export
 * @class TicketsApi
 * @extends {BaseAPI}
 */
export class TicketsApi extends BaseAPI {
    /**
     * 
     * @param {TicketReqDto} ticketReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createTicket(ticketReqDto: TicketReqDto, options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).createTicket(ticketReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TicketCheckReqDto} ticketCheckReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createTicketCheck(id: string, ticketCheckReqDto: TicketCheckReqDto, options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).createTicketCheck(id, ticketCheckReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} checkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public deleteTicketCheck(id: string, checkId: string, options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).deleteTicketCheck(id, checkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getAllTickets(options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getAllTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {GetTicketIdTypeEnum} [idType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicket(id: string, idType?: GetTicketIdTypeEnum, options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicket(id, idType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetTicketIdTypeEnum = {
    TECHNICAL: 'technical',
    QRCODE: 'qrcode'
} as const;
export type GetTicketIdTypeEnum = typeof GetTicketIdTypeEnum[keyof typeof GetTicketIdTypeEnum];


