import { FC, ReactNode } from 'react'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

type Props = {
  title?: string
  children: ReactNode
  filter?: ReactNode
}

export const ScreenWrapper: FC<Props> = ({ title, children, filter }) => {
  return (
    <Container sx={{ padding: (theme) => theme.spacing(3, 1), minWidth: '100%' }}>
      {title && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              sx={{ fontWeight: 400, typography: { sm: 'h3', xs: 'h5' }, marginBottom: { sm: '80px', xs: '10px' } }}
            >
              {title}
            </Typography>
          </Box>
          <Box>{filter}</Box>
        </Box>
      )}
      {children}
    </Container>
  )
}
